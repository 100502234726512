import React, { Component } from "react";
import styled from "styled-components";
import AppFeatureSection from "./AppFeatureSection";
import ContributionFlowSection from "./ContributionFlowSection";
import ContributionSection from "./ContributionSection";

class ShareBgImgSections extends Component {
  render() {
    return (
      <Wrapper>
        <AppFeatureSection />
        <ContributionFlowSection />
        <ContributionSection />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-image: url("/images/background02.png");
  background-size: cover;
  background-repeat: no-repeat;
`;

export default ShareBgImgSections;
