import React, { Component } from "react";
import styled from "styled-components";
import { animateScroll as aniScroll } from "react-scroll";
import * as Widget from "./Widget2";

class PromotionSection extends Component {
  render() {
    return (
      <Wrapper>
        <Widget.MaxWidth
          width={1280}
          css={`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
          `}
        >
          <div className="image-container">
            <img src="/images/promotion01.png" />
          </div>
          <div className="text-container">
            <h3>最強親子客製化繪本APP</h3>
            <h1>讀樂樂</h1>
            <h2>繪本創作募集</h2>
            <button onClick={() => this._scrollSection("contribution")}>
              立即報名 >>
            </button>
            <Widget.Row wrap="true" css="width: 100%;">
              <AppLink img="/images/icon-app-store.png" onClick={() => 0} />
              <AppLink img="/images/icon-google-play.png" onClick={() => 0} />
            </Widget.Row>
          </div>
        </Widget.MaxWidth>
      </Wrapper>
    );
  }

  _scrollSection(id) {
    const element = document.getElementById(id);
    const targetHeight = element.offsetTop;

    this.setState({ OpenMenu: false });
    aniScroll.scrollTo(targetHeight - 100, { duration: 400 });
  }
}

const Wrapper = styled.div`
  padding: 50px 40px;
  background-image: url("/images/background01.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;

  & .image-container {
    margin-right: 60px;

    & > img {
      max-width: 650px;
      width: 100%;

      @media screen and (max-width: 768px) {
        max-width: 400px;
      }

      @media screen and (max-width: 600px) {
        max-width: 260px;
      }
    }

    @media screen and (max-width: 414px) {
      margin: 0 auto 20px;
    }
  }

  & .text-container {
    flex: 1;

    & > h3 {
      font-weight: 400;
      font-size: 20px;
      color: #7c7c7c;
    }

    & > h1 {
      font-weight: 400;
      font-size: 40px;
      color: #71c5d1;
    }

    & > h2 {
      margin-bottom: 42px;
      font-weight: 400;
      font-size: 30px;
      color: #71c5d1;
    }

    & > button {
      margin-bottom: 90px;
      min-width: 177px;
      border-radius: 27.5px;
      border: 0px;
      padding: 6px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
      background: #f9cac2;
      font-size: 20px;
      color: white;
      cursor: pointer;

      :focus {
        outline: none;
      }
    }

    @media screen and (max-width: 768px) {
      & > h1 {
        font-weight: 400;
        font-size: 30px;
        color: #71c5d1;
      }
      & > h2 {
        margin-bottom: 30px;
      }

      & > button {
        margin-bottom: 30px;
      }
    }

    @media screen and (max-width: 600px) {
      & > h3 {
        font-weight: 400;
        font-size: 18px;
        color: #7c7c7c;
      }

      & > h2 {
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 20px;
        color: #71c5d1;
      }

      & > button {
        max-width: 177px;
        width: 100%;
        border-radius: 27.5px;
        padding: 6px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2),
          0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
        background: #f9cac2;
        font-size: 16px;
        color: white;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 414px) {
      text-align: center;
    }
  }
`;

const AppLink = styled.div`
  margin: 5px;
  background-color: lightgray;
  max-width: 188px;
  width: 100%;
  min-height: 56px;
  cursor: pointer;

  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: 768px) {
    max-width: 100px;
    width: 100%;
    min-height: 30px;
  }

  @media screen and (max-width: 414px) {
    margin: 0 auto;
  }
`;

export default PromotionSection;
