import React, { Component } from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import * as Widget from "./Widget2";

class ContributionFlowSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      article: null
    };
  }

  componentDidMount() {
    this._fetchArticle();
  }

  render() {
    let { showSpinner } = this.state;

    if (showSpinner) {
      return <Widget.Spinner />;
    }

    return (
      <Wrapper id="contribution_flow">
        <Widget.MaxWidth width={1280}>
          <h1>創作募集辦法</h1>
          <p dangerouslySetInnerHTML={this._createMarkUp()} />
        </Widget.MaxWidth>
        <div style={{ marginTop: 20 }}>
          <p>參考資料由 蕭宇珊 作家提供</p>
          <p>
            粉絲頁連結：
            <a
              href="https://www.facebook.com/yushan.illustration/"
              target="_blank"
              style={{ color: "#f5d89e" }}
            >
              雨傘繪 Yushan Illustration
            </a>
          </p>
        </div>
      </Wrapper>
    );
  }

  _fetchArticle = () => {
    let { appActions } = this.props;

    this.setState({ showSpinner: true });
    appActions
      .getArticle()
      .then(article => this.setState({ article }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _createMarkUp = () => {
    let { article } = this.state;

    return { __html: article && article.content };
  };
}

const Wrapper = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: column;
  & h1 {
    margin-bottom: 50px;
    border-left: 16px solid #f9cac2;
    padding-left: 18px;
    font-size: 40px;
    color: white;
  }

  & p {
    color: white;
  }

  @media screen and (max-width: 600px) {
    padding: 20px;
    & h1 {
      font-size: 30px;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(ContributionFlowSection);
