import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import * as Widget from "../Components/Widget2";
import * as Icon from "../Components/Icon";

class AppFeatureSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0
    };
  }

  render() {
    let { slideIndex } = this.state;

    return (
      <Wrapper id="app_feature">
        <Widget.MaxWidth width={1280}>
          <h1>App 特色</h1>
          <Carousel
            autoplay={true}
            autoplayInterval={3000}
            dragging={true}
            wrapAround
            slideIndex={slideIndex}
            afterSlide={slideIndex => this.setState({ slideIndex })}
            renderCenterLeftControls={this._renderLeftArrow}
            renderCenterRightControls={this._renderRightArrow}
            renderBottomCenterControls={this._renderDots}
          >
            <CarouselItem>
              <img className="image" src="/images/feature01.png" />
              <div className="slogan">
                <h2>{"客製化故事"}</h2>
                <p>{"想怎麼唸就怎麼唸\n還可以儲存喔！"}</p>
              </div>
            </CarouselItem>
            <CarouselItem>
              <img className="image" src="/images/feature02.png" />
              <div className="slogan">
                <h2>{"客製化人物"}</h2>
                <p>{"把角色的臉換成自己的\n營造更沈浸式的體驗"}</p>
              </div>
            </CarouselItem>
            <CarouselItem>
              <img className="image" src="/images/feature03.png" />
              <div className="slogan">
                <h2>{"錄音"}</h2>
                <p>{"讓孩童練習如何說故事\n並紀錄過程"}</p>
              </div>
            </CarouselItem>
            <CarouselItem>
              <img className="image" src="/images/feature04.png" />
              <div className="slogan">
                <h2>{"發送link分享"}</h2>
                <p>{"分享自己唸的故事\n發送link，一鍵搞定"}</p>
              </div>
            </CarouselItem>
            <CarouselItem>
              <img className="image" src="/images/feature05.png" />
              <div className="slogan">
                <h2>{"多本故事\n線上閱讀"}</h2>
                <p>{"豐富的故事書資料庫\n想讀什麼就讀什麼"}</p>
              </div>
            </CarouselItem>
          </Carousel>
        </Widget.MaxWidth>
      </Wrapper>
    );
  }

  _renderLeftArrow = ({ previousSlide }) => {
    return (
      <Icon.KeyboardArrowLeft
        onClick={() => previousSlide()}
        color={"#f3f3f3"}
        style={{ width: 60, height: 60, cursor: "pointer" }}
      />
    );
  };

  _renderRightArrow = ({ nextSlide }) => {
    return (
      <Icon.KeyboardArrowRight
        onClick={() => nextSlide()}
        color={"#f3f3f3"}
        style={{ width: 60, height: 60, cursor: "pointer" }}
      />
    );
  };

  _renderDots = () => {
    let { slideIndex } = this.state;
    const slides = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
    const activeColor = "#7E7E7F";

    return (
      <div style={{ display: "flex" }}>
        {slides.map((s, i) => (
          <Dot
            key={"slides" + i}
            css="margin-right: 12px;"
            color={i === slideIndex ? activeColor : undefined}
          />
        ))}
      </div>
    );
  };
}

const Wrapper = styled.div`
  padding: 40px 60px;
  & h1 {
    border-left: 16px solid #f9cac2;
    padding-left: 18px;
    font-size: 40px;
    color: white;
  }

  @media screen and (max-width: 600px) {
    padding: 30px 20px;
    & h1 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 414px) {
    padding: 30px 20px;
  }
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 550px;

  & > .image {
    margin-right: 10px;
    max-width: 530px;
    width: 100%;
  }

  & > .slogan {
    height: 150px;
    & > h2 {
      white-space: pre-wrap;
      font-size: 30px;
      color: white;
    }

    & > p {
      white-space: pre-wrap;
      font-size: 20px;
      color: white;
    }
  }

  @media screen and (max-width: 768px) {
    & > .image {
      max-width: 300px;
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    & > .image {
      margin-right: 20px;
      max-width: 250px;
      max-height: 250px;
    }
  }

  @media screen and (max-width: 414px) {
    height: 450px;
    & > .image {
      margin-right: 0px;
    }

    & > .slogan {
      & > h1 {
        font-size: 26px;
      }
    }

    flex-wrap: wrap;
  }
`;

const Dot = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${props => props.color || "white"};
  ${props => props.css};
`;

export default AppFeatureSection;
