import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import { validateEmail, validateMobileNumber } from "../Utils/FormUtil";
import * as Widget from "./Widget2";
import * as Icon from "./Icon";

const Type = {
  cooperation: "cooperation",
  contribute: "contribute"
};

class ContributionSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        email: "",
        phone: "",
        type: Type.cooperation,
        url: ""
      },
      showSpinner: false,
      errorMsg: "",
      showSuccessMsg: false
    };
  }

  render() {
    let { data, showSpinner, errorMsg, showSuccessMsg } = this.state;

    return (
      <Wrapper id="contribution">
        <Widget.MaxWidth width={1280}>
          <h1>參加徵稿</h1>
          <Container>
            {showSuccessMsg ? (
              <Widget.Center>
                <label style={{ marginBottom: 10 }}>已報名成功！</label>
              </Widget.Center>
            ) : (
              <>
                <div className="field">
                  <label>1. 姓名</label>
                  <input
                    value={data.name}
                    placeholder="請輸入真實姓名"
                    onChange={e =>
                      this.setState({ data: { ...data, name: e.target.value } })
                    }
                  />
                </div>
                <div className="field">
                  <label>2. 信箱</label>
                  <input
                    value={data.email}
                    placeholder="請輸入可用信箱"
                    onChange={e =>
                      this.setState({
                        data: { ...data, email: e.target.value }
                      })
                    }
                  />
                </div>
                <div className="field">
                  <label>3. 手機</label>
                  <input
                    value={data.phone}
                    placeholder="格式：0900000000"
                    onChange={e =>
                      this.setState({
                        data: { ...data, phone: e.target.value }
                      })
                    }
                  />
                </div>
                <div className="field">
                  <label>4. 參加類別</label>
                  <Widget.Row>
                    <div
                      className="checkbox"
                      onClick={() =>
                        this.setState({
                          data: { ...data, type: Type.cooperation }
                        })
                      }
                    >
                      {data.type === Type.cooperation ? (
                        <Icon.RadioButtonChecked
                          color="#e0e0e0"
                          style={{ marginRight: 9 }}
                        />
                      ) : (
                        <Icon.RadioButtonUnchecked
                          color="#e0e0e0"
                          style={{ marginRight: 9 }}
                        />
                      )}
                      <span>合作</span>
                    </div>
                    <div
                      className="checkbox"
                      onClick={() =>
                        this.setState({
                          data: { ...data, type: Type.contribute }
                        })
                      }
                    >
                      {data.type === Type.contribute ? (
                        <Icon.RadioButtonChecked
                          color="#e0e0e0"
                          style={{ marginRight: 9 }}
                        />
                      ) : (
                        <Icon.RadioButtonUnchecked
                          color="#e0e0e0"
                          style={{ marginRight: 9 }}
                        />
                      )}
                      <span>徵稿</span>
                    </div>
                  </Widget.Row>
                </div>
                <Widget.Col css="margin-bottom: 30px;">
                  <Widget.Row
                    wrap="true"
                    align="center"
                    css="margin-bottom: 20px;"
                  >
                    <label>5. 稿件上傳</label>
                    <span>請上傳至雲端並分享連結（權限請設定為開啟)</span>
                  </Widget.Row>
                  <input
                    value={data.url}
                    style={{ maxWidth: 673 }}
                    onChange={e =>
                      this.setState({ data: { ...data, url: e.target.value } })
                    }
                  />
                </Widget.Col>
                {errorMsg && (
                  <div
                    style={{ padding: 10, color: "red", textAlign: "center" }}
                  >
                    {errorMsg}
                  </div>
                )}
                {showSpinner && <Widget.Spinner />}
                <button onClick={this._submit}>送出</button>
              </>
            )}
          </Container>
        </Widget.MaxWidth>
      </Wrapper>
    );
  }

  _submit = () => {
    let { appActions } = this.props;
    let { data } = this.state;

    if (
      data.name === "" ||
      data.email === "" ||
      data.phone === "" ||
      data.url === ""
    ) {
      return this.setState({ errorMsg: "所有欄位為必填！" });
    }

    if (!validateMobileNumber(data.phone)) {
      return this.setState({ errorMsg: "請填入正確的手機密碼！" });
    }

    if (!validateEmail(data.email)) {
      return this.setState({ errorMsg: "請填入正確的電子信箱！" });
    }

    this.setState({ showSpinner: true });
    appActions
      .createContribution(data)
      .then(() => this.setState({ showSpinner: false, showSuccessMsg: true }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const Wrapper = styled.div`
  padding: 60px;
  & h1 {
    margin-bottom: 50px;
    border-left: 16px solid #f9cac2;
    padding-left: 18px;
    font-size: 40px;
    color: white;
  }

  @media screen and (max-width: 600px) {
    padding: 20px;
    & h1 {
      font-size: 30px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  padding: 60px 100px;
  background: white;

  & .field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  & label {
    margin-right: 20px;
    font-size: 20px;
    color: #7c7c7c;
  }

  & span {
    font-size: 15px;
    color: #7c7c7c;
  }

  & input {
    max-width: 373px;
    border: 0px;
    border-radius: 34px;
    padding: 10px 28px;
    background: #e0e0e0;
    font-size: 20px;
    color: white;

    ::placeholder {
      color: white;
    }

    :focus {
      outline: none;
    }
  }

  & .checkbox {
    display: flex;
    align-items: center;
    min-width: 100px;

    & > span {
      font-size: 20px;
      color: #7c7c7c;
    }
  }

  & button {
    margin: 0 auto;
    max-width: 168px;
    width: 100%;
    border-radius: 5px;
    padding: 13px;
    background: #f5d89e;
    font-size: 20px;
    color: white;
    cursor: pointer;

    :focus {
      outline: none;
    }
  }

  @media screen and (max-width: 414px) {
    padding: 50px;

    & label {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 375px) {
    padding: 40px 20px;
  }

  @media screen and (max-width: 320px) {
    & input {
      max-width: 240px;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(ContributionSection);
