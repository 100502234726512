import React from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import PromotionSection from "../../Components/PromotionSection";
import ShareBgImgSections from "../../Components/ShareBgImgSections";

class HomePage extends React.Component {
  render() {
    return (
      <Wrapper>
        <PromotionSection />
        <ShareBgImgSections />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

export default withPage(HomePage);
